.btn {
	display: inline-block;
	padding: 1.1em 2.3em;
	font-family: var(--font-family-button-link);
	text-transform: var(--text-transform-button-link);
	letter-spacing: var(--letter-spacing-button-link);

	font-size: var(--font-size-xs);

	text-align: center;
	border: 1px solid var(--color-accent);
	color: var(--color-title);
	cursor: pointer;
	transition: var(--timing);
	font-weight: 500;

	&:hover {
		background-color: var(--color-accent);;
		color: #fce3d7;
	}
}

.link {
	position: relative;
	transition: .1s;
	cursor: pointer;
	font-family: var(--font-family-button-link);
	text-transform: var(--text-transform-button-link);
	letter-spacing: var(--letter-spacing-button-link);
	color: var(--color-title);
	font-size: var(--font-size-xxs);
	font-weight: 500;
	padding-block: 0.5em;

	&:after {
		content: "";
		transition: var(--timing-half);
		position: absolute;
		height: 1px;
		inset-inline: 0;
		background: var(--color-accent);
		bottom: 0;
	}

	&:hover {
		&:after {
			transform: translateY(-.1em)
		}
	}
}

.modal__overlay {
	position: fixed;
	inset: 0;
	background: #7a766580;
	padding: 5%;
	overflow: auto;
	backdrop-filter: blur(4px);

	&.is-inactive {
		display: none;
	}
}

.modal__modal {
	position: relative;
	background: white;
	padding: 2rem;
	animation: fadeIn var(--timing);
	margin: auto;
	max-width: 100%;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
		0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
		0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

.modal__modal--md {
	width: 36rem;
}

.modal__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}

.modal__closeBtn {
	margin-left: auto;
	transition: var(--timing-half);

	&:hover {
		transform: scale(1.2);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(30px);
	}
	to {
		opacity: 1;
	}
}

.icon {
	width: 1.5rem;
	height: 1.5rem;
	display: inline-block;
	vertical-align: text-bottom;
}

.icon--sm {
	width: 1rem;
	height: 1rem;
}


.fomoCard {
	--offset: 1rem;
	transition: var(--timing);
	background: #fff;
	border-radius: 4px;
	padding: 1.2rem;
	position: fixed;
	z-index: 10;
	bottom: var(--offset);
	left: var(--offset);
	box-shadow: var(--box-shadow);
	width: 17rem;
	max-width: calc(65% - 2 * var(--offset));
	font-size: var(--font-size-xxs);
	line-height: 1.4;
}

.fomoCard__footer {
	display: flex;
	align-items: center;
	gap: .5em;
	margin-top: 1em;
}

.fomoCard__avatar {
	width: 1.6em;
	height: 1.6em;
	border-radius: 50%;
	background-color: #eee;
}

.fomoCard__name {
	font-weight: bold;
}

.fomoCard.is-hidden {
	opacity: 0;
	visibility: hidden;
	transform: translateX(-50%);
}

.audioBtn {
	--size: 3rem;
	width: var(--size);
	height: var(--size);
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #929386;
	color: white;
	border-radius: 50%;
	box-shadow: var(--box-shadow);
	z-index: 10;
}

.audioBtn.is-inactive {
	display: none;
}

.audioBtn__icon {
	display: none;
}

.audioBtn__icon--pause {
	width: 60%;
}

.audioBtn.is-playing .audioBtn__icon--pause,
.audioBtn.is-paused .audioBtn__icon--play {
	display: block;
}


.divider {
	&:after {
		content: "";
		display: block;
		margin: auto;
		--size: 4px;
		width: var(--size);
		height: var(--size);
		//aspect-ratio: 1;
		background: var(--color-divider);
		border-radius: 50%;
	}
}
.grid {
	display: grid;
}

.grid-cols-2 {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
	grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gap-grid {
	gap: 2rem;
}

.gap-0\.5 {
	gap: 0.5rem;
}

.gap-1 {
	gap: 1rem;
}

.gap-1\.2 {
	gap: 1.2rem;
}


.mb-0 {
	margin-bottom: 0;
}

.mb-0\.3 {
	margin-bottom: 0.3rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-1\.5 {
	margin-bottom: 1.5rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-3 {
	margin-bottom: 3rem;
}

.my-1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.section {
	padding-block: min(4rem, 10%) min(6rem, 15%);
}

.container {
	--padding-inline: 2rem;
	max-width: calc(1100px + 2 * var(--padding-inline));
	margin-inline: auto;
	padding-inline: var(--padding-inline);
}

.container--wide {
	max-width: calc(1800px + 2 * var(--padding-inline));
}
*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

a {
  color: inherit;
  text-decoration: none;
}

a, button {
  cursor: pointer;
  font: inherit;
}

button {
  background: none;
  border: none;
  padding: 0;
}

:root {
  --font-size-xxs: clamp(.75rem, -.2vi + .8rem, .64rem);
  --font-size-xs: clamp(.83rem, -.05vi + .84rem, .8rem);
  --font-size-sm: clamp(.91rem, .17vi + .87rem, 1rem);
  --font-size-base: clamp(1rem, .45vi + .89rem, 1.25rem);
  --font-size-md: clamp(1.1rem, .84vi + .89rem, 1.56rem);
  --font-size-lg: clamp(1.21rem, 1.35vi + .87rem, 1.95rem);
  --font-size-xl: clamp(1.33rem, 2.02vi + .83rem, 2.44rem);
  --font-size-xxl: clamp(1.46rem, 2.89vi + .74rem, 3.05rem);
  --font-size-xxxl: clamp(1.61rem, 4.01vi + .61rem, 3.81rem);
  --text-transform-montserrat: uppercase;
  --letter-spacing-montserrat: .12em;
  --font-family-title: "Marcellus", serif;
  --font-family-subtitle: "Montserrat", sans-serif;
  --font-family-body: "Marcellus", serif;
  --font-family-button-link: "Montserrat", sans-serif;
  --text-transform-subtitle: var(--text-transform-montserrat);
  --letter-spacing-subtitle: var(--letter-spacing-montserrat);
  --text-transform-button-link: var(--text-transform-montserrat);
  --letter-spacing-button-link: var(--letter-spacing-montserrat);
  --font-weight-body: 400;
  --font-weight-title: 400;
  --color-background: #f6f4ec;
  --color-body: #6d705e;
  --color-title: #545748;
  --color-subtitle: #a0a48e;
  --color-accent: #de967d;
  --color-divider: #a0a48e80;
  --white: white;
  --background-divider-fade-full: linear-gradient(to right, transparent, var(--color-divider), transparent);
  --background-divider-fade-left: linear-gradient(to right, transparent, var(--color-divider));
  --background-divider-fade-right: linear-gradient(to left, transparent, var(--color-divider));
  --timing: .3s ease-in-out;
  --timing-half: .15s ease-in-out;
  --gap-grid: 2rem;
  --box-shadow: 0px 1.5px 2.2px #00000002, 0px 3.7px 5.3px #00000003, 0px 7px 10px #00000004, 0px 12.5px 17.9px #00000005, 0px 23.4px 33.4px #00000006, 0px 56px 80px #00000008;
}

html {
  font-family: var(--font-family-body);
  font-weight: var(--font-weight-body);
  color: var(--color-body);
  font-optical-sizing: auto;
  font-style: normal;
  font-size: var(--font-size-base);
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(0deg, transparent, var(--color-background));
  background-repeat: no-repeat;
  line-height: 1.8;
}

.font-sm {
  font-size: var(--font-size-sm);
}

.font-base {
  font-size: var(--font-size-base);
}

.font-md {
  font-size: var(--font-size-md);
}

.font-lg {
  font-size: var(--font-size-lg);
}

.font-xl {
  font-size: var(--font-size-xl);
}

.font-xxl {
  font-size: var(--font-size-xxl);
}

.font-xxxl {
  font-size: var(--font-size-xxxl);
}

.prose > * + * {
  margin-top: 1.5em;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--font-family-title);
  color: var(--color-title);
  font-weight: var(--font-weight-title);
  margin-bottom: .3em;
  line-height: 1.6;
}

.h1 {
  font-size: var(--font-size-xxxl);
}

.h2 {
  font-size: var(--font-size-xxl);
}

.h3 {
  font-size: var(--font-size-xl);
}

.h4 {
  font-size: var(--font-size-lg);
}

.h5 {
  font-size: var(--font-size-md);
}

.h6 {
  font-size: var(--font-size-sm);
}

.strong {
  color: var(--color-title);
  font-weight: var(--font-weight-title);
}

.underline {
  text-decoration: underline from-font;
}

.lead {
  font-size: var(--font-size-md);
  text-wrap: balance;
  max-width: 40rem;
  margin-inline: auto;
  line-height: 1.6;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.section {
  padding-block: min(4rem, 10%) min(6rem, 15%);
}

.container {
  --padding-inline: 2rem;
  max-width: calc(1100px + 2 * var(--padding-inline));
  padding-inline: var(--padding-inline);
  margin-inline: auto;
}

.container--wide {
  max-width: calc(1800px + 2 * var(--padding-inline));
}

.btn {
  font-family: var(--font-family-button-link);
  text-transform: var(--text-transform-button-link);
  letter-spacing: var(--letter-spacing-button-link);
  font-size: var(--font-size-xs);
  text-align: center;
  border: 1px solid var(--color-accent);
  color: var(--color-title);
  cursor: pointer;
  transition: var(--timing);
  padding: 1.1em 2.3em;
  font-weight: 500;
  display: inline-block;
}

.btn:hover {
  background-color: var(--color-accent);
  color: #fce3d7;
}

.link {
  cursor: pointer;
  font-family: var(--font-family-button-link);
  text-transform: var(--text-transform-button-link);
  letter-spacing: var(--letter-spacing-button-link);
  color: var(--color-title);
  font-size: var(--font-size-xxs);
  padding-block: .5em;
  font-weight: 500;
  transition: all .1s;
  position: relative;
}

.link:after {
  content: "";
  transition: var(--timing-half);
  background: var(--color-accent);
  inset-inline: 0;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.link:hover:after {
  transform: translateY(-.1em);
}

.modal__overlay {
  backdrop-filter: blur(4px);
  background: #7a766580;
  padding: 5%;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.modal__overlay.is-inactive {
  display: none;
}

.modal__modal {
  animation: fadeIn var(--timing);
  background: #fff;
  max-width: 100%;
  margin: auto;
  padding: 2rem;
  position: relative;
  box-shadow: 0 1px 2px #00000012, 0 2px 4px #00000012, 0 4px 8px #00000012, 0 8px 16px #00000012, 0 16px 32px #00000012, 0 32px 64px #00000012;
}

.modal__modal--md {
  width: 36rem;
}

.modal__header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.modal__closeBtn {
  transition: var(--timing-half);
  margin-left: auto;
}

.modal__closeBtn:hover {
  transform: scale(1.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
  }
}

.icon {
  vertical-align: text-bottom;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
}

.icon--sm {
  width: 1rem;
  height: 1rem;
}

.fomoCard {
  --offset: 1rem;
  transition: var(--timing);
  z-index: 10;
  bottom: var(--offset);
  left: var(--offset);
  box-shadow: var(--box-shadow);
  max-width: calc(65% - 2 * var(--offset));
  font-size: var(--font-size-xxs);
  background: #fff;
  border-radius: 4px;
  width: 17rem;
  padding: 1.2rem;
  line-height: 1.4;
  position: fixed;
}

.fomoCard__footer {
  align-items: center;
  gap: .5em;
  margin-top: 1em;
  display: flex;
}

.fomoCard__avatar {
  background-color: #eee;
  border-radius: 50%;
  width: 1.6em;
  height: 1.6em;
}

.fomoCard__name {
  font-weight: bold;
}

.fomoCard.is-hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
}

.audioBtn {
  --size: 3rem;
  width: var(--size);
  height: var(--size);
  color: #fff;
  box-shadow: var(--box-shadow);
  z-index: 10;
  background: #929386;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.audioBtn.is-inactive, .audioBtn__icon {
  display: none;
}

.audioBtn__icon--pause {
  width: 60%;
}

.audioBtn.is-playing .audioBtn__icon--pause, .audioBtn.is-paused .audioBtn__icon--play {
  display: block;
}

.divider:after {
  content: "";
  --size: 4px;
  width: var(--size);
  height: var(--size);
  background: var(--color-divider);
  border-radius: 50%;
  margin: auto;
  display: block;
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gap-grid {
  gap: 2rem;
}

.gap-0\.5 {
  gap: .5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-1\.2 {
  gap: 1.2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.3 {
  margin-bottom: .3rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-1\.5 {
  margin-bottom: 1.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.banner {
  background-color: #5457489e;
  padding-block: .5rem;
}

.banner__content {
  color: #fff;
  justify-content: center;
  display: flex;
}

.banner__time {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.banner__timeBlock {
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
  display: flex;
}

.banner__timeBlock__value {
  font-variant-numeric: tabular-nums;
}

.banner__timeBlock__unit {
  font-size: var(--font-size-xxs);
  opacity: .5;
}

.main {
  overflow: hidden;
}

.section__header__seperator {
  margin-inline: auto;
  width: 62px;
  margin-bottom: 2rem;
}

.billboard__container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-block: min(6rem, 17%);
  display: flex;
}

.billboard__verticalLine {
  background: var(--color-divider);
  width: 1px;
  height: 50px;
  margin-inline: auto;
}

.billboard__content {
  text-align: center;
  color: var(--color-title);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.billboard__smallText {
  text-transform: uppercase;
  font-family: var(--font-family-subtitle);
  font-weight: var(--font-weight-subtitle);
  font-size: var(--font-size-xxs);
  color: var(--color-subtitle);
  letter-spacing: .15em;
  font-weight: 500;
}

.billboard__intro {
  margin-bottom: .4rem;
}

.billboard__names {
  font-size: var(--font-size-xxxl);
  font-family: var(--font-family-title);
  font-weight: var(--font-weight-title);
  margin-bottom: 1.2rem;
  line-height: 1;
}

.billboard__dateBlock__title {
  align-items: center;
  gap: min(6%, var(--gap-grid));
  white-space: nowrap;
  margin-bottom: .3rem;
  display: flex;
}

.billboard__dateBlock__title:before, .billboard__dateBlock__title:after {
  content: "";
  flex-grow: 1;
  height: 1px;
  display: block;
}

.billboard__dateBlock__title:before {
  background: var(--background-divider-fade-left);
}

.billboard__dateBlock__title:after {
  background: var(--background-divider-fade-right);
}

.billboard__dateBlock__date {
  font-family: var(--font-family-title);
  font-weight: var(--font-weight-title);
  font-size: var(--font-size-xl);
  margin-bottom: 2.5rem;
}

.mainComposition {
  margin-block: min(4rem, 6%);
}

.mainComposition__imagesGrid {
  isolation: isolate;
  grid-template-columns: 1fr 1.5fr 1fr;
  place-items: center;
  display: grid;
}

.mainComposition__imageWrapper--left {
  transform: rotate(-5deg)translateX(36%)translateY(5%);
}

.mainComposition__imageWrapper--right {
  transform: rotate(5deg)translateX(-36%)translateY(5%);
}

.mainComposition__imageWrapper--center {
  z-index: 1;
  position: relative;
}

.mainComposition__illustrationWrapper {
  position: absolute;
}

.mainComposition__illustrationWrapper--topLeft {
  z-index: -1;
  width: 55%;
  top: 0;
  left: 0;
  transform: translateY(-42%)translateX(-32%);
}

.mainComposition__illustrationWrapper--bottomRight {
  z-index: 1;
  width: 38%;
  bottom: 0;
  right: 0;
  transform: translateY(34%)translateX(31%)rotate(26deg);
}

.mainComposition__illustration  {
  width: 100%;
}

.section__header {
  text-align: center;
  margin-bottom: 2rem;
}

.section__header__img {
  max-width: 19%;
  margin-top: -3%;
  display: inline-block;
}

.plan__grid {
  gap: var(--gap-grid);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 3rem;
  display: grid;
}

@media (width <= 490px) {
  .plan__grid {
    grid-template-columns: 1fr;
  }
}

.plan__locationItem {
  text-align: center;
}

.plan__locationItem__locationImgWrapper {
  margin-bottom: 1.5rem;
  position: relative;
}

.plan__locationItem__locationImg {
  object-fit: cover;
  aspect-ratio: 1;
  border-radius: 8px;
  width: 100%;
  margin-inline: auto;
}

.plan__locationItem__flowerWrapper {
  z-index: -1;
  width: 50%;
  position: absolute;
}

.plan__locationItem:first-child .plan__locationItem__flowerWrapper {
  top: 0;
  left: 0;
  transform: translateY(-10%)translateX(-40%);
}

.plan__locationItem:nth-child(2) .plan__locationItem__flowerWrapper {
  bottom: 0;
  right: 0;
  transform: translateY(30%)translateX(30%);
}

.plan__locationItem__location__title {
  font-size: var(--font-size-md);
}

.plan__locationItem__location__address {
  margin-bottom: 1.4rem;
}

.info__grid {
  gap: var(--gap-grid);
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 3rem;
  display: grid;
}

.rsvp__card {
  background-color: var(--color-background);
  padding: min(4rem, 14%);
}

.rsvp__body, .feature {
  text-align: center;
}

.feature__iconWrapper {
  aspect-ratio: 1;
  background: var(--color-background);
  border-radius: 50%;
  place-content: center;
  width: 5rem;
  margin-bottom: 1rem;
  display: inline-grid;
}

.feature__icon {
  width: 3.5rem;
}

.feature__title {
  font-size: var(--font-size-md);
  font-family: var(--font-family-title);
}

.feature__content > * + * {
  margin-top: .5rem;
}

.feature__linkRow {
  margin-top: 1.2rem;
}

.gallery__grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  align-items: start;
  gap: 1rem;
  display: grid;
}

.gallery__gridCol {
  gap: 1rem;
  display: grid;
}

@media (width >= 751px) {
  .gallery__gridCol:nth-child(2n) {
    margin-top: 3rem;
  }
}

@media (width <= 750px) {
  .gallery__grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .gallery__gridCol:nth-child(2) {
    margin-top: 3rem;
  }

  .gallery__gridCol:nth-child(2n+3) {
    margin-top: -3rem;
  }
}

.gallery__imgWrapper {
  background-color: var(--color-background);
}

.gallery__img {
  object-fit: cover;
  aspect-ratio: .9;
  transition: var(--timing);
}

.gallery__img:hover {
  filter: grayscale(0);
}

.footer {
  font-size: var(--font-size-sm);
  border-top: 1px solid var(--color-divider);
  margin-top: 2rem;
  font-weight: 500;
}

.footer__container {
  text-align: center;
  padding-block: 1rem;
}

html.sr .section {
  visibility: hidden;
}

.audio {
  display: none;
}

.roomsTable {
  color: var(--color-title);
}

.roomsTable__item {
  justify-content: space-between;
  gap: 1rem;
  padding: .5rem .7rem;
  display: flex;
}

.roomsTable__item:nth-child(odd) {
  background: #e5e5e54f;
}

.roomsTable__roomName {
  color: var(--color-title);
  line-height: 1.3;
}

.roomsTable__details {
  font-size: var(--font-size-sm);
  color: var(--color-body);
  margin-top: .2em;
  line-height: 1.3;
}

.roomsTable__cell--price {
  white-space: nowrap;
  flex-shrink: 0;
}

.roomsNote {
  color: var(--color-title);
}

.iframeWrapper {
  margin-inline: -2rem;
}
/*# sourceMappingURL=index.47b84c82.css.map */

html {
	font-family: var(--font-family-body);
	font-weight: var(--font-weight-body);
	color: var(--color-body);
	font-optical-sizing: auto;
	font-style: normal;
	font-size: var(--font-size-base);
	line-height: 1.8;
	-webkit-font-smoothing: antialiased;
	background: linear-gradient(0deg, transparent, var(--color-background));
	background-repeat: no-repeat;
}

.font-sm {
	font-size: var(--font-size-sm);
}

.font-base {
	font-size: var(--font-size-base);
}

.font-md {
	font-size: var(--font-size-md);
}

.font-lg {
	font-size: var(--font-size-lg);
}

.font-xl {
	font-size: var(--font-size-xl);
}

.font-xxl {
	font-size: var(--font-size-xxl);
}

.font-xxxl {
	font-size: var(--font-size-xxxl);
}

.prose > * + * {
	margin-top: 1.5em;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: var(--font-family-title);
	color: var(--color-title);
	font-weight: var(--font-weight-title);
	margin-bottom: 0.3em;
	line-height: 1.6;
}

.h1 {
	font-size: var(--font-size-xxxl);
}

.h2 {
	font-size: var(--font-size-xxl);
}

.h3 {
	font-size: var(--font-size-xl);
}

.h4 {
	font-size: var(--font-size-lg);
}

.h5 {
	font-size: var(--font-size-md);
}

.h6 {
	font-size: var(--font-size-sm);
}

.strong {
	color: var(--color-title);
	font-weight: var(--font-weight-title);
}

.underline {
	text-decoration: underline;
	text-decoration-thickness: from-font;
}

.lead {
	font-size: var(--font-size-md);
	margin-inline: auto;
	max-width: 40rem;
	text-wrap: balance;
	line-height: 1.6;
}

.list-unstyled {
	list-style: none;
	padding: 0;
	margin: 0;
}
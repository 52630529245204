.banner {
	padding-block: .5rem;
	background-color: #5457489e;
}

.banner__content {
	color: white;
	display: flex;
	justify-content: center;
}

.banner__time {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.banner__timeBlock {
	display: flex;
	flex-direction: column;
	align-items: center;
	line-height: 1.2;
}

.banner__timeBlock__value {
	font-variant-numeric: tabular-nums;
}

.banner__timeBlock__unit {
	font-size: var(--font-size-xxs);
	opacity: .5;
}

.main {
	overflow: hidden;
}

.section__header__seperator {
	margin-inline: auto;
	margin-bottom: 2rem;
	width: 62px;
}

.billboard__container {
	text-align: center;
	display: flex;
	padding-block: min(6rem, 17%);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
}

.billboard__verticalLine {
	width: 1px;
	height: 50px;
	background: var(--color-divider);
	margin-inline: auto;
}


.billboard__content {
	/* color: white; */
	text-align: center;
	color: var(--color-title);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.billboard__smallText {
	text-transform: uppercase;
	font-family: var(--font-family-subtitle);
	font-weight: var(--font-weight-subtitle);
	font-size: var(--font-size-xxs);
	color: var(--color-subtitle);
	letter-spacing: .15em;
	font-weight: 500;
}

.billboard__intro {
	margin-bottom: .4rem;
}

.billboard__names {
	font-size: var(--font-size-xxxl);
	font-family: var(--font-family-title);
	font-weight: var(--font-weight-title);
	line-height: 1;
	margin-bottom: 1.2rem;
}

.billboard__dateBlock__title {
	align-items: center;
	gap: min(6%, var(--gap-grid));
	margin-bottom: .3rem;
	display: flex;
	white-space: nowrap;

	&:before,
	&:after {
		content: "";
		display: block;
		height: 1px;
		flex-grow: 1;
	}

	&:before {
		background: var(--background-divider-fade-left);
	}

	&:after {
		background: var(--background-divider-fade-right);
	}
}

.billboard__dateBlock__date {
	font-family: var(--font-family-title);
	font-weight: var(--font-weight-title);
	font-size: var(--font-size-xl);
	margin-bottom: 2.5rem;
}


.mainComposition {
	margin-block: min(4rem, 6%);
}


.mainComposition__imagesGrid {
	display: grid;
	grid-template-columns: 1fr 1.5fr 1fr;
	place-items: center;
	isolation: isolate;

}

.mainComposition__imageWrapper--left {
	transform: rotate(-5deg) translateX(36%) translateY(5%);

}

.mainComposition__imageWrapper--right {
	transform: rotate(5deg) translateX(-36%) translateY(5%);
}

.mainComposition__imageWrapper--center {
	position: relative;
	z-index: 1;
}


.mainComposition__illustrationWrapper {
	position: absolute;

}
.mainComposition__illustrationWrapper--topLeft {
	width: 55%;
	top: 0;
	left: 0;
	transform: translateY(-42%) translateX(-32%);
	z-index: -1;
}

.mainComposition__illustrationWrapper--bottomRight {
	width: 38%;
	bottom: 0;
	right: 0;
	transform: translateY(34%) translateX(31%) rotate(26deg);
	z-index: 1;
}

.mainComposition__illustration {
	width: 100%;
}

.section__header {
	text-align: center;
	margin-bottom: 2rem;
}

.section__header__img {
	display: inline-block;
	margin-top: -3%;
	max-width: 19%;
}

.section__header__title {}

.section__header__lead {}

.plan__grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: var(--gap-grid);
	row-gap: 3rem;
}

@media (max-width: 490px) {
	.plan__grid {
		grid-template-columns: 1fr;
	}
}

.plan__locationItem {
	text-align: center;
}

.plan__locationItem__locationImgWrapper {
	position: relative;
	margin-bottom: 1.5rem;
}

.plan__locationItem__locationImg {
	object-fit: cover;
	aspect-ratio: 1;
	width: 100%;
	border-radius: 8px;
	margin-inline: auto;
}

.plan__locationItem__flowerWrapper {
	position: absolute;
	z-index: -1;
	width: 50%;
}

.plan__locationItem__flower {
	
}

.plan__locationItem:nth-child(1) {
	.plan__locationItem__flowerWrapper {
		top: 0;
		left:0;
		transform: translateY(-10%) translateX(-40%);
	}
}
.plan__locationItem:nth-child(2) {
	.plan__locationItem__flowerWrapper {
		right: 0;
		bottom: 0;
		transform: translateY(30%) translateX(30%);
	}
}

.plan__locationItem__location {}

.plan__locationItem__location__title {
	font-size: var(--font-size-md);
}

.plan__locationItem__location__address {
	margin-bottom: 1.4rem;
}

.info__grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: var(--gap-grid);
	row-gap: 3rem;
}

.rsvp__card {
	background-color: var(--color-background);
	padding: min(4rem, 14%);
}

.rsvp__body {
	text-align: center;
}

.rsvp__btn {}

.feature {
	text-align: center;
}

.feature__iconWrapper {
	display: inline-grid;
	place-content: center;
	width: 5rem;
	aspect-ratio: 1;
	border-radius: 50%;
	background: var(--color-background);
	margin-bottom: 1rem;
}

.feature__icon {
	width: 3.5rem;
}

.feature__title {
	font-size: var(--font-size-md);
	font-family: var(--font-family-title);
}

.feature__content>*+* {
	margin-top: .5rem;
}

.feature__linkRow {
	margin-top: 1.2rem;
}

.gallery__grid {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 1rem;
	align-items: start;
}

.gallery__gridCol {
	display: grid;
	gap: 1rem;

}

@media (min-width: 751px) {
	.gallery__gridCol:nth-child(even) {
		margin-top: 3rem;
	}
}

@media (max-width: 750px) {
	.gallery__grid {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	.gallery__gridCol:nth-child(2) {
		margin-top: 3rem;
	}

	.gallery__gridCol:nth-child(2n+3) {
		margin-top: -3rem;
	}
}

.gallery__imgWrapper {
	background-color: var(--color-background);
}

.gallery__img {
	object-fit: cover;
	aspect-ratio: 0.9;
	transition: var(--timing);

	&:hover {
		filter: grayscale(0);
	}
}

.footer {
	font-size: var(--font-size-sm);
	margin-top: 2rem;
	font-weight: 500;
	border-top: 1px solid var(--color-divider);
}

.footer__container {
	text-align: center;
	padding-block: 1rem;
}

html.sr .section {
	visibility: hidden;
}

.audio {
	display: none;
}

.roomsTable {
	color: var(--color-title);
}

.roomsTable__item {
	display: flex;
	justify-content: space-between;
	padding: .5rem .7rem;
	gap: 1rem;
}

.roomsTable__item:nth-child(odd) {
	background: #e5e5e54f;
}

.roomsTable__roomName {
	color: var(--color-title);
	line-height: 1.3;
}

.roomsTable__details {
	font-size: var(--font-size-sm);
	color: var(--color-body);
	line-height: 1.3;
	margin-top: .2em;
}

.roomsTable__cell--price {
	flex-shrink: 0;
	white-space: nowrap;
}

.roomsNote {
	color: var(--color-title);
}

.iframeWrapper {
	margin-inline: -2rem;
}
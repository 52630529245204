:root {
	--font-size-xxs: clamp(0.75rem, -0.2vi + 0.8rem, 0.64rem);
	--font-size-xs: clamp(0.83rem, -0.05vi + 0.84rem, 0.8rem);
	--font-size-sm: clamp(0.91rem, 0.17vi + 0.87rem, 1rem);
	--font-size-base: clamp(1rem, 0.45vi + 0.89rem, 1.25rem);
	--font-size-md: clamp(1.1rem, 0.84vi + 0.89rem, 1.56rem);
	--font-size-lg: clamp(1.21rem, 1.35vi + 0.87rem, 1.95rem);
	--font-size-xl: clamp(1.33rem, 2.02vi + 0.83rem, 2.44rem);
	--font-size-xxl: clamp(1.46rem, 2.89vi + 0.74rem, 3.05rem);
	--font-size-xxxl: clamp(1.61rem, 4.01vi + 0.61rem, 3.81rem);

	--text-transform-montserrat: uppercase;
	--letter-spacing-montserrat: 0.12em;

	--font-family-title: "Marcellus", serif;
	--font-family-subtitle: "Montserrat", sans-serif;
	--font-family-body: "Marcellus", serif;
	--font-family-button-link: "Montserrat", sans-serif;
	
	--text-transform-subtitle: var(--text-transform-montserrat);
	--letter-spacing-subtitle: var(--letter-spacing-montserrat);
	--text-transform-button-link: var(--text-transform-montserrat);
	--letter-spacing-button-link: var(--letter-spacing-montserrat);

	--font-weight-body: 400;
	--font-weight-title: 400;

	--color-background: #f6f4ec;
	--color-body: #6d705e;
	--color-title: #545748;
	--color-subtitle: #a0a48e;
	--color-accent: #de967d;
	--color-divider: rgba(160, 164, 142, 0.5);
	--white: white;

	--background-divider-fade-full: linear-gradient(to right, transparent, var(--color-divider), transparent);
	--background-divider-fade-left: linear-gradient(to right, transparent, var(--color-divider));
	--background-divider-fade-right: linear-gradient(to left, transparent, var(--color-divider));

	--timing: 0.3s ease-in-out;
	--timing-half: 0.15s ease-in-out;
	--gap-grid: 2rem;

	--box-shadow:
			0px 1.5px 2.2px rgba(0, 0, 0, 0.008),
			0px 3.7px 5.3px rgba(0, 0, 0, 0.012),
			0px 7px 10px rgba(0, 0, 0, 0.015),
			0px 12.5px 17.9px rgba(0, 0, 0, 0.018),
			0px 23.4px 33.4px rgba(0, 0, 0, 0.022),
			0px 56px 80px rgba(0, 0, 0, 0.03);

}
